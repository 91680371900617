<template>
  <NavBar/>
  <div class="main-bg">
    <h2 class="title">
      {{ $t('Безопасность') }}
    </h2>
    <p>
      {{ $t('info5') }}
    </p>
    <p>
      {{ $t('info6') }}
    </p>
    <p>
      {{ $t('info7') }}
    </p>
    <p>
      <b>
        {{ $t('info8') }}
      </b>
    </p>
    <div class="safety-image">
      <div class="safety-image-text">
        <h2>
          {{ $t('info9') }}
        </h2>
        <div>
          {{ $t('info10') }}
        </div>
      </div>
      <div class="safety-image-img">
        <picture class="picture">
          <img src="../assets/imgs/2.png" alt="">
        </picture>
      </div>
    </div>
  </div>
  <ContactForm/>
</template>

<script>
import NavBar from '../components/NavBar'
import ContactForm from '../components/ContactForm'

export default {
  name: 'Safety',
  components: {
    NavBar,
    ContactForm
  }
}
</script>

<style scoped>

.safety-image {
  display: flex;
  align-items: center;
}

h2 {
  margin: 0 0 50px 0;
}

p {
  font-size: 20px;
}

@media (max-width: 575px) {
  p {
    font-size: 16px;
  }

  h2 {
    margin: 0 0 25px 0;
  }

  .safety-image-text {
    font-size: 16px;
  }
}

</style>
